var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"informationCardWrapper"},[_c('v-card',{staticClass:"information-card",class:{
			'remove-bottom-border-radius': _vm.show,
			'disabled-card': _vm.isCardDisabled,
			'defaulted-card': _vm.isDefaulter,
			'birthday-gif': _vm.isBirthDate,
		},attrs:{"min-width":"300px"}},[_c('div',{staticClass:"top-content-wrapper"},[_c('div',{staticClass:"subtitle color-secondary fw-600 text-uppercase"},[_c('v-chip',{attrs:{"outlined":"","x-small":"","color":"primary"}},[_vm._t("topLeft")],2)],1),_c('div',{staticClass:"subtitle text-right color-secondary fw-600 text-uppercase"},[_vm._t("topRight")],2)]),_c('div',{staticClass:"main-content-wrapper"},[_c('div',{staticClass:"main-content-left"},[_c('div',{staticClass:"main-content"},[_vm._t("mainContent")],2),_c('div',{staticClass:"subtitle"},[_vm._t("mainContentSubtitle")],2)]),_c('div',{staticClass:"main-content-right"},[_vm._t("mainContentRight")],2)]),_c('div',{staticClass:"more-info subtitle"},[_vm._t("moreInfo")],2),_c('div',{staticClass:"bottom-content-wrapper"},[_c('v-card-actions',[_c('div',{staticClass:"action-button"},[_vm._t("actionButtons")],2),_c('v-spacer'),(_vm.expandCard)?_c('div',[_c('v-btn',{attrs:{"color":"tertiary","icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1):_vm._e()],1)],1)]),_c('v-card',{staticClass:"expansionPanelWrapper",class:{
			'disabled-card': _vm.isCardDisabled,
			'defaulted-card': _vm.isDefaulter,
		}},[_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-divider'),_vm._t("expandCardContent")],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }