import { render, staticRenderFns } from "./InformationCard.vue?vue&type=template&id=0ef8e8c0&scoped=true&"
import script from "./InformationCard.vue?vue&type=script&lang=js&"
export * from "./InformationCard.vue?vue&type=script&lang=js&"
import style0 from "./InformationCard.vue?vue&type=style&index=0&id=0ef8e8c0&lang=scss&scoped=true&"
import style1 from "./InformationCard.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef8e8c0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VChip,VDivider,VExpandTransition,VIcon,VSpacer})
